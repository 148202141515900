import React, {FunctionComponent, useState, useCallback, useRef, useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import ErrorPage from 'src/pages/500';
import Layout from 'src/components/Layout';
import Header from 'src/components/Header';
import Main from 'src/components/Main';
import Footer from 'src/components/Footer';
import useTimeout from 'src/hooks/useTimeout';

import '../assets/scss/main.scss';

export type Props = {
  location: {pathname: string};
};

const IndexPage: FunctionComponent<Props> = ({location}) => {
  const [currentArticle, setCurrentArticle] = useState<string | null>(null);
  const [homeIsVisible, setHomeIsVisible] = useState(true);
  const [articleIsVisible, setArticleIsVisible] = useState(false);
  const isLoading = useTimeout(100, true);
  const mainElement = useRef<HTMLElement>(null);

  const handleOpenArticle = useCallback(article => {
    // Animate transitions
    setCurrentArticle(article);
    setTimeout(() => {
      setHomeIsVisible(false);
      setTimeout(() => {
        setArticleIsVisible(true);
      }, 25);
    }, 325);
  }, []);

  const handleCloseArticle = useCallback(() => {
    // Animate transitions
    setArticleIsVisible(false);
    setTimeout(() => {
      setHomeIsVisible(true);
      setTimeout(() => {
        setCurrentArticle(null);
      }, 25);
    }, 325);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const {target} = event;
      // We haven't clicked inside an article
      if (mainElement.current && !mainElement.current.contains(target as Node)) {
        // And there is one displayed
        if (currentArticle) {
          handleCloseArticle();
        }
      }
    },
    [currentArticle, handleCloseArticle]
  );

  // Listen for document clicks
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentArticle, handleCloseArticle]);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Layout location={location}>
        <div className={`body ${isLoading ? 'is-loading' : ''} ${currentArticle ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={handleOpenArticle} style={homeIsVisible ? {} : {display: 'none'}} />
            <Main
              ref={mainElement}
              article={currentArticle}
              articleIsVisible={articleIsVisible}
              onCloseArticle={handleCloseArticle}
              style={!homeIsVisible ? {display: 'flex'} : {display: 'none'}}
            />
            <Footer style={homeIsVisible ? {} : {display: 'none'}} />
          </div>
          <div id="bg" />
        </div>
      </Layout>
    </ErrorBoundary>
  );
};

export default IndexPage;
