import React, {forwardRef, RefForwardingComponent, useContext, CSSProperties} from 'react';

import AboutPage from 'src/articles/AboutPage';
import ContactPage from 'src/articles/ContactPage';

export type Handle = HTMLElement;

export type Props = {
  style?: CSSProperties;
  article: string | null;
  articleIsVisible: boolean;
  onCloseArticle: () => void;
};

const Main: RefForwardingComponent<Handle, Props> = ({article, articleIsVisible, onCloseArticle, style}, ref) => {
  return (
    <main id="main" ref={ref} style={style}>
      <AboutPage
        className={`${article === 'about' ? 'active' : ''} ${articleIsVisible ? 'timeout' : ''}`}
        style={{display: 'none'}}
        onCloseArticle={onCloseArticle}
      />
      <ContactPage
        className={`${article === 'contact' ? 'active' : ''} ${articleIsVisible ? 'timeout' : ''}`}
        style={{display: 'none'}}
        onCloseArticle={onCloseArticle}
      />
    </main>
  );
};

export default forwardRef(Main);
