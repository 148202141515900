import React, {FunctionComponent, CSSProperties} from 'react';
import fmarchalPicture from 'src/images/fmarchal.jpg';

export type Props = {
  style?: CSSProperties;
  className?: string;
  onCloseArticle: () => void;
};

const AboutPage: FunctionComponent<Props> = ({style, className, onCloseArticle}) => (
  <article id="about" style={style} className={className}>
    <h2 className="major">À Propos</h2>
    <span className="image main" />
    <p>
      <a href="https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-marchal-6078b413/" target="_blank">
        <img
          src={fmarchalPicture}
          alt="Frédéric Marchal"
          height="120"
          style={{float: 'left', marginRight: '16px', marginBottom: '16px'}}
        />
      </a>
      Fondée en 2003 par{' '}
      <a href="https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-marchal-6078b413/" target="_blank">
        Frédéric Marchal
      </a>
      , Barberine est une holding animatrice qui participe activement au développement des sociétés dans lesquelles elle
      possède une participation.
    </p>
    <br />
    <h4>Adresse du siège</h4>
    <ul>
      <li>10 Rue René Descartes</li>
      <li>78320, Le Mesnil-Saint-Denis</li>
    </ul>
    <a href="https://www.google.fr/maps/dir//10+Rue+René+Descartes,+78320+Le+Mesnil-Saint-Denis/" target="_blank">
      <img
        width="100%"
        style={{marginBottom: '16px'}}
        src="https://maps.googleapis.com/maps/api/staticmap?center=10+Rue+René+Descartes,+78320+Le+Mesnil-Saint-Denis&zoom=8&scale=2&size=480x160&maptype=terrain&key=AIzaSyDvvLZg9BJ0lof1wJN6ZSNYDaBYh0ZxqDw&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xb0bec5%7Clabel:%7C10+Rue+René+Descartes,+78320+Le+Mesnil-Saint-Denis"
        alt="Google Map of 10 Rue René Descartes, 78320 Le Mesnil-Saint-Denis"
      />
    </a>
    <div className="close" onClick={onCloseArticle} />
  </article>
);

export default AboutPage;

/*
Je n’ai pas grand-chose à dire à propos de Barberine, si ce n’est :

Barberine est une holding animatrice qui a été créée en 2003 à l’initiative de Frédéric MARCHAL. Depuis sa création, Barberine participe activement au développement des sociétés dans lesquelles a pris une participation.
Son siège social est situé au 10 rue Descartes - 78320 LE MESNIL SAINT DENIS
Contact : frederic.marchal@me.com

Faudra ajouter un peu de graphisme autour de la landing page.
On peut aussi ajouter une page contact complet : nom, prénom, adresse, texte du message …
Un lien vers mon compte linkedin …

Dis moi si ca te va ou si tu veux plus.

Il faudrait passer voir Catherine pour lui expliquer Joonca mardi ou mercredi prochain si ca te va .


*/
