import React, {FunctionComponent, CSSProperties} from 'react';

export type Props = {
  style?: CSSProperties;
  onOpenArticle: (s: string) => void;
};

const Header: FunctionComponent<Props> = ({style, onOpenArticle}) => (
  <header id="header" style={style}>
    <div className="logo">
      <span className="icon fa-flag" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Barberine</h1>
        <p style={{marginTop: '-1rem', marginBottom: '1rem'}}>Holding Animatrice</p>
        <p>"Si haute soit la montagne, on y trouve un sentier"</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              onOpenArticle('about');
            }}
          >
            À Propos
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              onOpenArticle('contact');
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
